import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { getChats, fetchChats } from './ChatSlice';
const API_URL = '/api/data/chat/ChatData';
function* fetchChatsSaga() {
  try {
    const response = yield axios.get(`${API_URL}`);
    yield put(getChats(response.data));
  } catch (err) {
    throw new Error(err);
  }
}

export default function* chatSaga() {
  yield takeLatest(fetchChats.type, fetchChatsSaga);
}
