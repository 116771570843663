import { takeLatest, put } from 'redux-saga/effects';
import { AES, enc } from 'crypto-js';
import { $host, $authHost } from '../axios';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  logout,
} from './authSlice';

// login
function* loginSaga(action) {
  try {
    const { email, password } = action.payload;
    const response = yield $host.post('/auth/user_login', { email, password });

    if (response.data.data.user.level === 3001) {
      yield put(loginSuccess(response.data.data.user));

      const currentUser = response.data.data.user;

      if (currentUser.auth_token) {
        localStorage.setItem('token', response.data.data.user.auth_token);
      }

      const userString = JSON.stringify(currentUser);

      const timestamp = new Date().getTime();

      const userData = {
        data: userString,
        timestamp,
      };

      const encryptedUser = AES.encrypt(
        JSON.stringify(userData),
        process.env.REACT_APP_USER_DATA_ENC_KEY,
      ).toString();

      localStorage.setItem('currentUser', encryptedUser);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(loginFailure(errorPreview));
    } else {
      yield put(loginFailure(error.message));
    }
  }
}

// register
function* registerSaga(action) {
  try {
    const { UserName, email, password, confirmPassword } = action.payload;
    if (password !== confirmPassword) {
      const error = { message: 'Password not matched', description: 'Password not matched' };
      yield put(registerFailure(error));
    } else {
      const registerPayload = {
        name: UserName,
        email,
        password,
        roll: '4',
        referral_code: 'hSsI13868tKL3660',
        phone: '0000000',
      };
      const response = yield $host.post('/users/signup', registerPayload);
      if (response.status === 200) {
        const success = { message: response.data.message, description: response.data.description };
        yield put(registerSuccess(success));
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(registerFailure(errorPreview));
    }
  }
}

// logout
function* logoutSaga() {
  yield localStorage.removeItem('currentUser');
}

export default function* authSaga() {
  yield takeLatest(loginStart.type, loginSaga);
  yield takeLatest(registerStart.type, registerSaga);
  yield takeLatest(logout.type, logoutSaga);
}
