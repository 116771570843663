import { createSlice } from '@reduxjs/toolkit';
import {
  saveCartToSessionStorage,
  loadCartFromSessionStorage,
  loadPaymentIntentFromSessionStorage,
} from 'utils/sessionStorage';

const initialState = {
  cartItems: loadCartFromSessionStorage() || [],
  paymentData: loadPaymentIntentFromSessionStorage() || null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const serviceToCart = action.payload || {};
      const {
        business,
        service,
        booking_date_time: { date: service_booking_date, start_time: service_booking_time } = {},
      } = serviceToCart;

      if (service && service.id) {
        const index = state.cartItems.findIndex(
          (existingItem) =>
            existingItem.service.id === service.id
        );

        if (index === -1) {
          state.cartItems.push({
            business,
            service,
            service_booking_date,
            service_booking_time,
          });
        } else {
          state.cartItems[index] = {
            ...state.cartItems[index],
            ...serviceToCart,
          };
        }
      }

      saveCartToSessionStorage(state.cartItems);
    },
    removeFromCart: (state, action) => {
      const itemsToRemove = action.payload || {}
      
      if (itemsToRemove && itemsToRemove.id) {
        const index = state.cartItems.findIndex(
          (existingItem) =>
            existingItem.service.id === itemsToRemove.id 
        );

        if (index !== -1) {
          state.cartItems.splice(index, 1);
        }
      }

      saveCartToSessionStorage(state.cartItems);
    },
    updateCartItem: (state, action) => {
      const updatedItem = action.payload || {};
      const {
        service,
        booking_date_time: { date: service_booking_date, start_time: service_booking_time } = {},
        business,
      } = updatedItem;

      if (service && service.id) {
        const index = state.cartItems.findIndex(
          (existingItem) =>
            existingItem.service.id === service.id
        );

        if (index !== -1) {
          state.cartItems[index] = {
            ...state.cartItems[index],
            ...updatedItem,
          };
        }
      }

      saveCartToSessionStorage(state.cartItems);
    },
    addPaymentData: (state, action) => {
      state.paymentData = action.payload;
      // Optionally, save payment data to session storage
    },
    clearCart: (state) => {
      state.cartItems = [];
      sessionStorage.removeItem('cartItems');
    },
    clearPaymentData: (state) => {
      state.paymentData = null;
      sessionStorage.removeItem('paymentIntentData');
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  updateCartItem,
  addPaymentData,
  clearCart,
  clearPaymentData,
} = cartSlice.actions;
export default cartSlice.reducer;
