import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  specialistSchedule: {},
  error: null,
};

const specialistScheduleSlice = createSlice({
  name: 'specialistSchedule',
  initialState,
  reducers: {
    fetchSpecialistSchedule: (state) => {
      state.isLoading = true;
    },
    fetchSpecialistScheduleSuccess: (state, action) => {
      state.isLoading = false;
      state.specialistSchedule = action.payload;
    },
    fetchSpecialistScheduleFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSpecialistScheduleState: (state) => {
      state.isLoading = false;
      state.specialistSchedule = null;
      state.error = null;
    },
  },
});

export const {
  fetchSpecialistSchedule,
  fetchSpecialistScheduleSuccess,
  fetchSpecialistScheduleFailure,
  resetSpecialistScheduleState,
} = specialistScheduleSlice.actions;

export default specialistScheduleSlice.reducer;
