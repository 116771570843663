import { takeLatest, put, call } from 'redux-saga/effects';
import {
  fetchReviewsRequest,
  fetchReviewsSuccess,
  fetchReviewsFailure,
  submitReviewRequest,
  submitReviewSuccess,
  submitReviewFailure,
} from './serviceReviewSlice';
import { $host, $authHost } from '../axios';

// Fetch Reviews Saga
function* fetchReviewsSaga(action) {
  try {
    const response = yield call(
      $host.get,
      `/business-services-review/service-review/${action.payload.serviceId}`,
    );
    yield put(fetchReviewsSuccess(response.data.data.service_reviews));
  } catch (error) {
    yield put(fetchReviewsFailure(error.message));
  }
}

// Submit Review Saga
function* submitReviewSaga(action) {
  try {
    yield call(
      $authHost.post,
      '/business-services-review/service-review/',
      action.payload.reviewData,
    );
    yield put(submitReviewSuccess());
    yield put(fetchReviewsRequest({ serviceId: action.payload.serviceId }));
  } catch (error) {
    yield put(submitReviewFailure(error.message));
  }
}

export default function* serviceReviewSaga() {
  yield takeLatest(fetchReviewsRequest.type, fetchReviewsSaga);
  yield takeLatest(submitReviewRequest.type, submitReviewSaga);
}
