
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chats: [],
  chatContent: 1,
  chatSearch: '',
  isLoading: false,
  error: null,
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    fetchChats: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    getChats: (state, action) => {
      state.chats = action.payload;
    },
    SearchChat: (state, action) => {
      state.chatSearch = action.payload;
    },
    SelectChat: (state, action) => {
      state.chatContent = action.payload;
    },
    sendMsg: {
      reducer: (state, action) => {
        state.chats = state.chats.map((chat) =>
          chat.id === action.payload.id
            ? {
                ...chat,
                ...chat.chatHistory[0][1].to.push(action.payload.chatMsg),
              }
            : chat,
        );
      },

      prepare: (id, chatMsg) => {
        return {
          payload: { id, chatMsg },
        };
      },
    },
  },
});

export const { SearchChat, getChats, sendMsg, SelectChat, fetchChats } = ChatSlice.actions;

export default ChatSlice.reducer;
