import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoriesList: [],
  recommendList: [],
  popularBusinessesNear: [],
  popularSpecialit: [],
  topDiscountService: [],
  subServicesByServiceId: [],
  isLoading: false,
  success: null,
  error: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // get Businesses which are Categories == services

    getCategories: (state) => {
      state.isLoading = true;
    },

    getCategoriesSuccess: (state, action) => {
      state.isLoading = false;
      state.categoriesList = action.payload;
    },

    getCategoriesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // get sub service by service id
    getSubServiceByServiceId: (state, action) => {
      state.isLoading = true;
    },
    getSubServiceByServiceIdSuccess: (state, action) => {
      state.isLoading = false;
      state.subServicesByServiceId = action.payload;
    },

    // popular-specialist
    getPopularSpecialist: (state, action) => {
      state.isLoading = true;
    },
    getPopularSpecialistSuccess: (state, action) => {
      state.isLoading = false;
      state.popularSpecialit = action.payload;
    },

    // get recommended
    getRecommended: (state) => {
      state.isLoading = true;
    },

    getRecommendedSuccess: (state, action) => {
      state.isLoading = false;
      state.recommendList = action.payload.businesses_near;
      state.popularBusinessesNear = action.payload.popular_businesses_near;
    },

    getRecommendedFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // top Discount service
    getTopDiscountService: (state) => {
      state.isLoading = true;
    },

    getTopDiscountServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.topDiscountService = action.payload;
    },

    getTopDiscountServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetDashboardState: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getPopularSpecialist,
  getPopularSpecialistSuccess,
  getRecommended,
  getRecommendedSuccess,
  getRecommendedFailure,
  getTopDiscountService,
  getTopDiscountServiceSuccess,
  getTopDiscountServiceFailure,
  getSubServiceByServiceId,
  getSubServiceByServiceIdSuccess,
  resetDashboardState,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
