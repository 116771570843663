import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
const MidLayout = Loadable(lazy(() => import('../layouts/MidLayout')));

/***** Pages ****/
const Dashboard = Loadable(lazy(() => import('../views/dashboards/dashboard')));
const Categories = Loadable(lazy(() => import('../views/categories/categories')));
const Profile = Loadable(lazy(() => import('../views/profile/index')));
const CardsDetails = Loadable(lazy(() => import('../views/profile/cardsDetails')));
const ServiceDetailsPage = Loadable(
  lazy(() => import('../views/serviceDetailPage/serviceDetailPage')),
);
const ServiceDetail = Loadable(lazy(() => import('../views/serviceDetail/serviceDetail')));
const ServiceMenu = Loadable(lazy(() => import('../views/serviceMenu/serviceMenu')));
const BookService = Loadable(lazy(() => import('../views/bookService/bookService')));
const BookAppointment = Loadable(lazy(() => import('../views/bookAppointment/bookAppointment')));
const BookCancelation = Loadable(
  lazy(() => import('../views/bookingCancellationReasons/bookingCancellationReasons')),
);
const Payment = Loadable(lazy(() => import('../views/payment/payment')));
const DetailsPage = Loadable(lazy(() => import('../views/detailsPage/detailsPage')));
const Settings = Loadable(lazy(() => import('../views/settings/settings')));
const Chat = Loadable(lazy(() => import('../views/apps/chat/Chat')));
const FavoriteBookmarks = Loadable(lazy(() => import('../views/favorite/favoriteBookmarks')));
const Bookings = Loadable(lazy(() => import('../views/bookings/bookings')));
const Businesses = Loadable(lazy(() => import('../views/businesses/businesses')));
const PopularSpecialists = Loadable(
  lazy(() => import('../views/popularSpecialists/popularSpecialists')),
);
const BusinessSpecialists = Loadable(
  lazy(() => import('../views/businessSpecialists/businessSpecialists')),
);
const ServiceSpecialists = Loadable(
  lazy(() => import('../views/servicesSpecialists/servicesSpecialists')),
);

const BusinessServices = Loadable(
  lazy(() => import('../views/businessesServices/businessesServices')),
);
const Notifications = Loadable(lazy(() => import('../views/notifications/notifications')));
const About = Loadable(lazy(() => import('../views/aboutUs/aboutUs')));
const Privacy = Loadable(lazy(() => import('../views/privacyPolicies/privacyPolicies')));
const TermsCondition = Loadable(lazy(() => import('../views/terms&conditions/term&conditions')));
const Reschedule = Loadable(lazy(() => import('../views/bookings/rescheduleBooking')));
const RateYourExperience = Loadable(
  lazy(() => import('../views/rateYourExperience/rateYourExperience')),
);
/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const VerifyOTP = Loadable(lazy(() => import('../views/auth/verifyOtp')));
const NewPassword = Loadable(lazy(() => import('../views/auth/newpassword')));
const RescheduleBooking = Loadable(
  lazy(() => import('../views/rescheduleBooking/rescheduleBooking')),
);

const BookingTimeChange = Loadable(
  lazy(() => import('../views/businessesServices/bookingTimeChange')),
);
/*****Routes******/
// cart
const Cart = Loadable(lazy(() => import('../views/cart/cart')));
const PayNow = Loadable(lazy(() => import('../views/paynow/paynow')));
const ThemeRoutes = (user, userEmailForForgotPwd, isOTPPinMatched) => [
  {
    path: '/',
    element: user ? <FullLayout /> : <Navigate to="/login" />,
    // element: <FullLayout />,
    private: true,
    children: [
      { path: '/dashboard', name: 'Dashboard', exact: true, element: <Dashboard /> },
      { path: '/categories', name: 'Categories', exact: true, element: <Categories /> },
      { path: '/business-details', name: 'DetailsPage', exact: true, element: <DetailsPage /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: user ? <MidLayout /> : <Navigate to="/login" />,
    // element: <MidLayout />,
    private: true,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        exact: true,
        element: <Profile />,
      },
      {
        path: '/cards-details',
        name: 'CardsDetails',
        exact: true,
        element: <CardsDetails />,
      },
      {
        path: '/service-details',
        name: 'ServiceDetails',
        exact: true,
        element: <ServiceDetailsPage />,
      },
      {
        path: '/service-detail',
        name: 'ServiceDetail',
        exact: true,
        element: <ServiceDetail />,
      },
      {
        path: '/service-menu',
        name: 'ServiceMenu',
        exact: true,
        element: <ServiceMenu />,
      },
      // {
      //   path: '/cart',
      //   name: 'Cart',
      //   exact: true,
      //   element: <Cart />,
      // },
      {
        path: '/pay-now',
        name: 'PayNow',
        exact: true,
        element: <PayNow />,
      },
      {
        path: '/cart',
        name: 'BookAppointment',
        exact: true,
        element: <BookAppointment />,
      },
      {
        path: '/booking-cancelation',
        name: 'bookingCancelation',
        exact: true,
        element: <BookCancelation />,
      },
      {
        path: '/booking-time-change',
        name: 'bookingTimeChange',
        exact: true,
        element: <BookingTimeChange />,
      },
      {
        path: '/payment',
        name: 'Payment',
        exact: true,
        element: <Payment />,
      },
      {
        path: '/book-service',
        name: 'BookService',
        exact: true,
        element: <BookService />,
      },
      // { path: '/reschedule', name: 'reschedule', exact: true, element: <Reschedule /> },
      {
        path: '/reschedule-booking',
        name: 'reschedule-booking',
        exact: true,
        element: <Reschedule />,
      },
      {
        path: '/rate-experience',
        name: 'rate-experience',
        exact: true,
        element: <RateYourExperience />,
      },
      { path: '/settings', name: 'Settings', exact: true, element: <Settings /> },
      { path: '/messages', name: 'Chat', exact: true, element: <Chat /> },
      { path: '/favorite', name: 'Bookmarks', exact: true, element: <FavoriteBookmarks /> },
      { path: '/bookings', name: 'Bookings', exact: true, element: <Bookings /> },
      { path: '/businesses', name: 'Businesses', exact: true, element: <Businesses /> },
      {
        path: '/popular-specialists',
        name: 'businessesSpecialists',
        exact: true,
        element: <PopularSpecialists />,
      },
      {
        path: '/business-specialists',
        name: 'businessSpecialists',
        exact: true,
        element: <BusinessSpecialists />,
      },
      {
        path: '/service-specialists',
        name: 'serviceSpecialists',
        exact: true,
        element: <ServiceSpecialists />,
      },
      {
        path: '/business-serviceses',
        name: 'businessServices',
        exact: true,
        element: <BusinessServices />,
      },
      { path: '/notifications', name: 'Notifications', exact: true, element: <Notifications /> },
      { path: '/about', name: 'About', exact: true, element: <About /> },
      { path: '/privacy', name: 'Privacy', exact: true, element: <Privacy /> },
      { path: '/terms', name: 'Terms', exact: true, element: <TermsCondition /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      {
        path: 'recoverpwd',
        element: userEmailForForgotPwd === '' ? <RecoverPassword /> : <Navigate to="/verify-otp" />,
      },
      {
        path: 'verify-otp',
        element: userEmailForForgotPwd !== '' ? <VerifyOTP /> : <Navigate to="/login" />,
      },
      {
        path: 'new-password',
        element:
          userEmailForForgotPwd !== '' && isOTPPinMatched === 'true' ? (
            <NewPassword />
          ) : (
            <Navigate to="/verify-otp" />
          ),
      },
    ],
  },
];

export default ThemeRoutes;
