import { takeLatest, put } from 'redux-saga/effects';
import { AES, enc } from 'crypto-js';
import {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyOTP,
  verifyOTPSuccess,
  verifyOTPFailure,
  addNewPassword,
  addNewPasswordSuccess,
  addNewPasswordFailure,
  emailForForgotPwd,
} from './forgotpwdSlice';
import { $host, $authHost } from '../axios';

// step one
function* forgotPasswd(action) {
  const { payload } = action;
  try {
    const response = yield $host.post('/users/forgot-password', payload);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      const encryptedUserEmail = AES.encrypt(
        payload.email,
        process.env.REACT_APP_RESET_PASSWORD_ENC_KEY,
      ).toString();
      localStorage.setItem('resetUserEmail', encryptedUserEmail);
      yield put(forgotPasswordSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(forgotPasswordFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(forgotPasswordFailure(errorPreview));
    }
  }
}

// step 2
function* verifyOneTimePassword(action) {
  const { payload } = action;
  try {
    const response = yield $host.post('/users/verify-pin', payload);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(verifyOTPSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(verifyOTPFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(verifyOTPFailure(errorPreview));
    }
  }
}
// step 3
function* setNewPassword(action) {
  const { payload } = action;
  try {
    const response = yield $host.post('/users/new-password', payload);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(addNewPasswordSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(addNewPasswordFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(addNewPasswordFailure(errorPreview));
    }
  }
}

export default function* forgotPwdSaga() {
  yield takeLatest(forgotPassword.type, forgotPasswd);
  yield takeLatest(verifyOTP.type, verifyOneTimePassword);
  yield takeLatest(addNewPassword.type, setNewPassword);
}
