import { call, put, takeLatest } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import { bookServicesRequest, bookServicesSuccess, bookServicesFailure } from './bookServiceSlice';
import { clearCart, addPaymentData } from 'store/cart/cartSlice';
import { savePaymentIntentToSessionStorage } from 'utils/sessionStorage';

function* bookServicesSaga(action) {
  const { cartItems, payload, api, navigate } = action.payload;
  try {
    const response = yield call($authHost.post, 'booking/book-services', payload);

    if (response.data.code === 400) {
      api.error({
        description: response.data.description,
      });
    }

    const { order_id, order_price } = response.data.data;
    if (response.data.code === 200) {
      yield put(
        bookServicesSuccess({
          orderId: order_id,
          orderPrice: order_price,
        }),
      );

      const paymentData = {
        order_id: Number(order_id),
        amount: Math.round(parseFloat(order_price) * 100), // pound to pence
        currency: 'GBP',
      };

      const paymentResponse = yield call($authHost.post, 'booking/create-payment', paymentData);

      const paymentIntentData = {
        order_id,
        amount: paymentData.amount,
        currency: paymentData.currency,
        payment_intent: paymentResponse.data.payment_intent,
        items: cartItems,
      };

      yield put(clearCart());
      savePaymentIntentToSessionStorage(paymentIntentData);
      yield put(addPaymentData(paymentIntentData));
      navigate('/pay-now');
    }
  } catch (error) {
    yield put(bookServicesFailure(error.response?.data?.message || 'Booking failed'));
  }
}

export default function* bookServicesRootSaga() {
  yield takeLatest(bookServicesRequest.type, bookServicesSaga);
}
