import { takeLatest, put, call } from 'redux-saga/effects';
import { $authHost } from '../axios';
import {
  fetchSpecialistSchedule,
  fetchSpecialistScheduleSuccess,
  fetchSpecialistScheduleFailure,
} from './serviceEssentialsSlice';

function* fetchSpecialistScheduleSaga(action) {
  const { specialistId } = action.payload;
  try {
    const response = yield call($authHost.get, `/business-specialists/business-specialist-schedule/${specialistId}`);

    if (response.status === 200) {
      yield put(fetchSpecialistScheduleSuccess(response.data.data.business_specialist_schedule[0]));
    }
  } catch (error) {
    let errorPreview;
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      errorPreview = { code, message, description };
    } else {
      errorPreview = { message: 'Network Error' };
    }
    yield put(fetchSpecialistScheduleFailure(errorPreview));
  }
}

export default function* specialistScheduleSaga() {
  yield takeLatest(fetchSpecialistSchedule.type, fetchSpecialistScheduleSaga);
}
