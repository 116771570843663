import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  success: null,
  error: null,
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    passwordChangeStart: (state) => {
      state.isLoading = true;
      state.success = null;
      state.error = null;
    },
    passwordChangeScuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    passwordChangeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // reset states
    resetSettingsState: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  passwordChangeStart,
  passwordChangeScuccess,
  passwordChangeFailure,
  resetSettingsState,
} = settings.actions;
export default settings.reducer;
