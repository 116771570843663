import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allBusinesses: [],
  businessServicesList: [],
  businessSpecialistsList: [],
  businessServiceDetails: [],
  businessServiceTypes: [],
  businessDetails: {},
  businessSchedule: {},
  isLoading: false,
  success: null,
  error: null,
};

export const businessesSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    getAllBusinesses: (state) => {
      state.isLoading = true;
    },
    getAllBusinessesSuccess: (state, action) => {
      state.isLoading = false;
      state.allBusinesses = action.payload;
    },

    // business service types
    getAllBusinessesServiceTypes: (state) => {
      state.isLoading = true;
    },
    getAllBusinessesServiceTypesSuccess: (state, action) => {
      state.isLoading = false;
      state.businessServiceTypes = action.payload;
    },

    // get businesses services
    getBusinessServices: (state, action) => {
      state.isLoading = true;
    },
    getBusinessServicesSuccess: (state, action) => {
      state.isLoading = false;
      state.businessServicesList = action.payload;
    },

    // get businesses specialists
    getBusinessSpecialists: (state, action) => {
      state.isLoading = true;
    },
    getBusinessSpecialistsSuccess: (state, action) => {
      state.isLoading = false;
      state.businessSpecialistsList = action.payload;
    },

    // get a specific business service
    getBusinessService: (state, action) => {
      state.isLoading = true;
    },
    getBusinessServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.businessServiceDetails = action.payload;
    },

    // get business details
    getBusinessDetails: (state, action) => {
      state.isLoading = true;
    },
    getBusinessDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.businessDetails = action.payload.business_details;
      state.businessSchedule = action.payload.business_schedule;
    },
  },
});

export const {
  getAllBusinesses,
  getAllBusinessesSuccess,
  getBusinessServices,
  getBusinessServicesSuccess,
  getBusinessSpecialists,
  getBusinessSpecialistsSuccess,
  getBusinessService,
  getBusinessServiceSuccess,
  getBusinessDetails,
  getBusinessDetailsSuccess,
  getAllBusinessesServiceTypes,
  getAllBusinessesServiceTypesSuccess,
} = businessesSlice.actions;

export default businessesSlice.reducer;
