import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aboutUs: '',
  privacyPolicies: '',
  termsConditions: '',
  isLoading: false,
  success: null,
  error: null,
};

const commonsSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    // about us
    getAboutUs: (state, action) => {
      state.isLoading = true;
    },
    getAboutUsSuccess: (state, action) => {
      state.isLoading = false;
      state.aboutUs = action.payload;
    },
    getAboutUsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // privacy policies
    getPrivacyPolicies: (state, action) => {
      state.isLoading = true;
    },
    getPrivacyPoliciesSuccess: (state, action) => {
      state.isLoading = false;
      state.privacyPolicies = action.payload;
    },
    getPrivacyPoliciesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // terms condition
    getTermsConditions: (state, action) => {
      state.isLoading = true;
    },
    getTermsConditionsSuccess: (state, action) => {
      state.isLoading = false;
      state.termsConditions = action.payload;
    },
    getTermsConditionsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    // reset states
    resetCommonStates: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getAboutUs,
  getAboutUsSuccess,
  getAboutUsFailure,
  getPrivacyPolicies,
  getPrivacyPoliciesSuccess,
  getPrivacyPoliciesFailure,
  getTermsConditions,
  getTermsConditionsSuccess,
  getTermsConditionsFailure,
  resetCommonStates,
} = commonsSlice.actions;

export default commonsSlice.reducer;
