import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    success: false,
    orderId: null,
    orderPrice: null,
};

const bookServicesSlice = createSlice({
    name: 'bookServices',
    initialState,
    reducers: {
        bookServicesRequest(state) {
            state.loading = true;
            state.error = null;
            state.success = false;
        },
        bookServicesSuccess(state, action) {
            state.loading = false;
            state.success = true;
            state.orderId = action.payload.orderId;
            state.orderPrice = action.payload.orderPrice;
        },
        bookServicesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        resetBookingState(state) {
            return initialState;
        },
    },
});

export const {
    bookServicesRequest,
    bookServicesSuccess,
    bookServicesFailure,
    resetBookingState,
} = bookServicesSlice.actions;

export default bookServicesSlice.reducer;
