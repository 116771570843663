import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export const StripeContext = React.createContext();

export const StripeProvider = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_PUBLIC_PUBLISHABLE_KEY);
      setStripe(stripeInstance);
    };
    initializeStripe();
  }, []);

  return stripe && <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>;
};
