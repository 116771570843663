import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  success: null,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state) => {
      state.isLoading = true;
    },
    updateProfileSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    updateProfileFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetProfileState: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const { updateProfile, updateProfileSuccess, updateProfileFailure, resetProfileState } =
  profileSlice.actions;
export default profileSlice.reducer;
