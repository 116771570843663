const SESSION_STORAGE_KEY = 'cartItems';
const EXPIRATION_TIME = 2 * 60 * 60 * 1000;

const PAYMENT_INTENT_KEY = 'paymentIntentData';
const PAYMENT_EXPIRATION_TIME = 2 * 60 * 60 * 1000;

export const saveCartToSessionStorage = (items) => {
  const data = {
    items,
    timestamp: new Date().getTime(),
  };
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
};

export const loadCartFromSessionStorage = () => {
  const data = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
  if (!data) return [];

  const currentTime = new Date().getTime();
  if (currentTime - data.timestamp > EXPIRATION_TIME) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    return [];
  }
  return data.items;
};

export const savePaymentIntentToSessionStorage = (paymentIntentData) => {
  const data = {
    paymentIntentData,
    timestamp: new Date().getTime(),
  };
  sessionStorage.setItem(PAYMENT_INTENT_KEY, JSON.stringify(data));
};

export const loadPaymentIntentFromSessionStorage = () => {
  const data = JSON.parse(sessionStorage.getItem(PAYMENT_INTENT_KEY));
  if (!data) return null;

  const currentTime = new Date().getTime();
  if (currentTime - data.timestamp > PAYMENT_EXPIRATION_TIME) {
    sessionStorage.removeItem(PAYMENT_INTENT_KEY);
    return null;
  }
  return data.paymentIntentData;
};
