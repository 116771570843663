import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import { updateProfile, updateProfileFailure, updateProfileSuccess } from './profileSlice';

function* profileUpdate(action) {
  const { payload } = action;
  try {
    const formdata = new FormData();
    const payloadObj = payload.fields;
    formdata.append('name', payloadObj.firstname + payloadObj.lastname);
    formdata.append('address_line1', payloadObj.address_line1);
    formdata.append('address_line2', payloadObj.address_line2);
    formdata.append('city', payloadObj.city);
    formdata.append('phone', payloadObj.phone);
    formdata.append('post_code', payloadObj.post_code);
    formdata.append('state', payloadObj.state);
    formdata.append('image', payloadObj.image);

    const response = yield $authHost.patch(`/users/update-profile-web/${payload.userId}`, formdata);

    if (response.status === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(updateProfileSuccess(success));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateProfileFailure(errorPreview));
    }
  }
}

export default function* profileSaga() {
  yield takeLatest(updateProfile.type, profileUpdate);
}
