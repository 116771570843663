import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favoriteBusinesses: [],
  favoriteServices: [],
  isLoading: false,
  success: null,
  error: null,
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    // get favorite businesses
    getFavoriteBusinesses: (state, action) => {
      state.isLoading = true;
    },
    getFavoriteBusinessesSuccess: (state, action) => {
      state.isLoading = false;
      state.favoriteBusinesses = action.payload;
    },
    // add favorite business
    addFavoriteBusiness: (state, action) => {
      state.isLoading = true;
    },
    addFavoriteBusinessSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // remove favorite business
    removeFavoriteBusiness: (state, action) => {
      state.isLoading = true;
    },
    removeFavoriteBusinessSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // get favorite services
    getFavoriteServices: (state, action) => {
      state.isLoading = true;
    },
    getFavoriteServicesSuccess: (state, action) => {
      state.isLoading = false;
      state.favoriteServices = action.payload;
    },
    // add favorite service
    addFavoriteService: (state, action) => {
      state.isLoading = true;
    },
    addFavoriteServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // remove favorite service
    removeFavoriteService: (state, action) => {
      state.isLoading = true;
    },
    removeFavoriteServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    // handle errors
    favoriteError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetFavoriteStates: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getFavoriteBusinesses,
  getFavoriteBusinessesSuccess,
  addFavoriteBusiness,
  addFavoriteBusinessSuccess,
  removeFavoriteBusiness,
  removeFavoriteBusinessSuccess,
  getFavoriteServices,
  getFavoriteServicesSuccess,
  addFavoriteService,
  addFavoriteServiceSuccess,
  removeFavoriteService,
  removeFavoriteServiceSuccess,
  favoriteError,
  resetFavoriteStates,
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
