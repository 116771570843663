import { createSlice } from '@reduxjs/toolkit';
import { AES, enc } from 'crypto-js';
let userEmailToResetPasswordFromLocalStorage = '';
const encryptedUserEmail = localStorage.getItem('resetUserEmail');

if (encryptedUserEmail) {
  try {
    const decryptedUserEmailBytes = AES.decrypt(
      encryptedUserEmail,
      process.env.REACT_APP_RESET_PASSWORD_ENC_KEY,
    );
    const decryptedUserEmailString = decryptedUserEmailBytes.toString(enc.Utf8);

    userEmailToResetPasswordFromLocalStorage = decryptedUserEmailString;
  } catch (error) {
    console.error('Error decrypting user email data:', error);
  }
}

const initialState = {
  userEmailForForgotPwd: userEmailToResetPasswordFromLocalStorage,
  isOTPPinMatched: () => localStorage.getItem('pinMatched'),
  isLoading: false,
  success: null,
  error: null,
};

const forgotPwdSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    // forgot password
    forgotPassword: (state) => {
      state.isLoading = true;
    },
    forgotPasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    forgotPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    //verfiyOtp
    verifyOTP: (state, action) => {
      state.isLoading = true;
    },
    verifyOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    verifyOTPFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    emailForForgotPwd: (state, action) => {
      state.userEmailForForgotPwd = action.payload;
    },
    // new password
    addNewPassword: (state, action) => {
      state.isLoading = true;
    },
    addNewPasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    addNewPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // reset states
    resetForgotPasswordStates: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  emailForForgotPwd,
  verifyOTP,
  verifyOTPSuccess,
  verifyOTPFailure,
  addNewPassword,
  addNewPasswordSuccess,
  addNewPasswordFailure,
  resetForgotPasswordStates,
} = forgotPwdSlice.actions;

export default forgotPwdSlice.reducer;
