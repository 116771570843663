import { takeLatest, put, call } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import {
  getBookingList,
  getBookingListSuccess,
  getBookingListFailure,
  getBusinessBookings,
  getBusinessBookingsSuccess,
  getBusinessBookingsFailure,
  cancelBookingRequest,
  cancelBookingSuccess,
  cancelBookingFailure,
  resetBookingsState,
} from './bookingsSlice';

function* getBookings(action) {
  const { payload } = action;
  const { userId } = payload;

  try {
    const response = yield call($authHost.get, `/booking/user-bookings/${userId}`);

    yield put(getBookingListSuccess(response.data.data.business_categories));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBookingListFailure(errorPreview));
    }
  }
}

function* getAllBookingsList(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/booking/business-bookings/${payload}`);
    if (response.data.code === 200) {
      yield put(getBusinessBookingsSuccess(response.data.data.business_categories));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(getBusinessBookingsFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getBusinessBookingsFailure(errorPreview));
    }
  }
}

function* cancelBooking(action) {
  const { payload } = action;
  const { booking_id, service_id, reason } = payload;

  try {
    yield call($authHost.post, '/booking/cancel-booking', { booking_id, service_id, reason });
    yield put(cancelBookingSuccess());
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(cancelBookingFailure(errorPreview));
    } else {
      yield put(cancelBookingFailure({ message: 'An unknown error occurred.' }));
    }
  }
}

export default function* bookingsSaga() {
  yield takeLatest(getBookingList.type, getBookings);
  yield takeLatest(cancelBookingRequest.type, cancelBooking);
  yield takeLatest(getBusinessBookings.type, getAllBookingsList);
}
