import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from '../axios';
import {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailure,
  getSubServiceByServiceId,
  getSubServiceByServiceIdSuccess,
  getPopularSpecialist,
  getPopularSpecialistSuccess,
  getRecommended,
  getRecommendedSuccess,
  getRecommendedFailure,
  getTopDiscountService,
  getTopDiscountServiceSuccess,
  getTopDiscountServiceFailure,
  resetDashboardState,
} from './dashboardSlice';

// All categories === All services
function* categoriesList() {
  try {
    const response = yield $host.get(`/services/all-services`);
    yield put(getCategoriesSuccess(response.data.data.services));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getCategoriesFailure(errorPreview));
    }
  }
}
// get All Sub Services of A service== categories
function* subServiceByServiceId(action) {
  const { payload } = action;
  const response = yield $host.get(`/sub-services/all-sub-services/${payload}`);
  yield put(getSubServiceByServiceIdSuccess(response.data.data.sub_services));
  yield put(resetDashboardState());
}

// popular specialists
function* popularSpecialit() {
  try {
    const response = yield $host.get(`/business-specialists/popular-specialist`);
    yield put(getPopularSpecialistSuccess(response.data.data.specialists));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      console.error(errorPreview);
      // yield put(getRecommendedFailure(errorPreview));
    }
  }
}

function* recommendList(action) {
  const { searchQuery, postCode, lat, lng } = action.payload;
  let url = `/businesses/recommended`;

  let queryParams = `?lat=${lat}&lng=${lng}`;

  if (searchQuery) {
    queryParams += `&search=${searchQuery}`;
  }
  if (postCode) {
    queryParams += `&postCode=${postCode}`;
  }

  url += queryParams;
  try {
    const response = yield $host.get(url);
    yield put(getRecommendedSuccess(response.data.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getRecommendedFailure(errorPreview));
    }
  }
}

function* topDiscountService() {
  try {
    const response = yield $host.get(`/business-services/discount-services`);
    yield put(getTopDiscountServiceSuccess(response.data.data.services));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getTopDiscountServiceFailure(errorPreview));
    }
  }
}

export default function* dashboardSaga() {
  yield takeLatest(getCategories.type, categoriesList);
  yield takeLatest(getPopularSpecialist.type, popularSpecialit);
  yield takeLatest(getRecommended.type, recommendList);
  yield takeLatest(getTopDiscountService.type, topDiscountService);
  yield takeLatest(getSubServiceByServiceId.type, subServiceByServiceId);
}
