import axios from 'axios';

const baseURL = process.env.REACT_APP_PUBLIC_API_URL;

console.log('baseURLbaseURLbaseURL',baseURL)

const $host = axios.create({
  baseURL,
});

const $authHost = axios.create({
  baseURL,
});

const authInterceptor = (config) => {
  if (typeof window !== 'undefined') {
    // const token = localStorage.getItem('token')
    //   ? localStorage.getItem('token')
    //   : sessionStorage.getItem('token');
    config.headers['x-auth-token'] = localStorage.getItem('token');
  }

  return config;
};

$authHost.interceptors.request.use(authInterceptor);

export { $host, $authHost };
