import { takeLatest, put, call } from 'redux-saga/effects';
import { $authHost } from '../axios';
import {
  getFavoriteBusinesses,
  getFavoriteBusinessesSuccess,
  addFavoriteBusiness,
  addFavoriteBusinessSuccess,
  removeFavoriteBusiness,
  removeFavoriteBusinessSuccess,
  getFavoriteServices,
  getFavoriteServicesSuccess,
  addFavoriteService,
  addFavoriteServiceSuccess,
  removeFavoriteService,
  removeFavoriteServiceSuccess,
  favoriteError,
} from './favoriteSlice';

function* fetchFavoriteBusinesses(action) {
  const { userId } = action.payload;
  try {
    const response = yield call(
      $authHost.get,
      `/favorite-business/all-favorite-business/${userId}`,
    );
    yield put(getFavoriteBusinessesSuccess(response.data.data.favorite_business));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(favoriteError(error.response.data));
    }
  }
}

// user_id
// business_id
// business_name
// business_address
// business_image

function* postFavoriteBusiness(action) {
  const { payload } = action;
  try {
    const response = yield call(
      $authHost.post,
      '/favorite-business/add-favorite-business',
      payload,
    );
    yield put(addFavoriteBusinessSuccess(response.data.message));
    yield put(getFavoriteBusinesses({ userId: payload.user_id }));

  } catch (err) {
    if (err.response && err.response.data) {
      yield put(favoriteError(err.response.data));
    }
  }
}

function* deleteFavoriteBusiness(action) {
  const { favoriteId, userId } = action.payload;
  try {
    const response = yield call(
      $authHost.delete,
      `/favorite-business/remove-favorite-business/${favoriteId}`,
    );
    yield put(removeFavoriteBusinessSuccess(response.data.message));
    yield put(getFavoriteBusinesses({ userId }));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(favoriteError(error.response.data));
    }
  }
}

function* fetchFavoriteServices(action) {
  const { userId } = action.payload;
  try {
    const response = yield call(
      $authHost.get,
      `/favorite-services/all-favorite-services/${userId}`,
    );
    yield put(getFavoriteServicesSuccess(response.data.data.business_services));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(favoriteError(error.response.data));
    }
  }
}

// business_service_id
// user_id
// business_id

function* postFavoriteService(action) {
  const { payload } = action;
  try {
    const response = yield call($authHost.post, '/favorite-services/add-favorite-services', payload);
    yield put(addFavoriteServiceSuccess(response.data.message));
    yield put(getFavoriteServices({ userId: payload.user_id }));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(favoriteError(error.response.data));
    }
  }
}

function* deleteFavoriteService(action) {
  const { favoriteId, userId } = action.payload;

  try {
    const response = yield call(
      $authHost.delete,
      `/favorite-services/remove-favorite-services/${favoriteId}`,
    );
    yield put(removeFavoriteServiceSuccess(response.data.message));
    yield put(getFavoriteServices({ userId }));
  } catch (error) {
    if (error.response && error.response.data) {
      yield put(favoriteError(error.response.data));
    }
  }
}

export default function* favoriteSaga() {
  yield takeLatest(getFavoriteBusinesses.type, fetchFavoriteBusinesses);
  yield takeLatest(addFavoriteBusiness.type, postFavoriteBusiness);
  yield takeLatest(removeFavoriteBusiness.type, deleteFavoriteBusiness);
  yield takeLatest(getFavoriteServices.type, fetchFavoriteServices);
  yield takeLatest(addFavoriteService.type, postFavoriteService);
  yield takeLatest(removeFavoriteService.type, deleteFavoriteService);
}
