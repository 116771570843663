import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  success: null,
  error: null,
  bookingSchedules: [], 
};

const bookingReschedule = createSlice({
  name: 'bookingReschedule',
  initialState,
  reducers: {
    updateBookingSchedule: (state) => {
      state.loading = true;
    },
    updateBookingScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    updateBookingScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllBookingSchedules: (state) => {   
      state.loading = true;
    },
    getAllBookingSchedulesSuccess: (state, action) => { 
      state.loading = false;
      state.bookingSchedules = action.payload.bookingSchedules;
    },
    getAllBookingSchedulesError: (state, action) => {  
      state.loading = false;
      state.error = action.payload;
    },
    resetBookingScheduleState: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
      state.bookingSchedules = []; 
    },
  },
});

export const {
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,                
  getAllBookingSchedulesSuccess,        
  getAllBookingSchedulesError,         
  resetBookingScheduleState,
} = bookingReschedule.actions;

export default bookingReschedule.reducer;
