import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getAboutUs,
  getAboutUsSuccess,
  getAboutUsFailure,
  getPrivacyPolicies,
  getPrivacyPoliciesSuccess,
  getPrivacyPoliciesFailure,
  getTermsConditions,
  getTermsConditionsSuccess,
  getTermsConditionsFailure,
  resetCommonStates,
} from './commonsSlice';

function* aboutUs() {
  try {
    const response = yield $host.get(`/terms_conditions/about`);
    yield put(getAboutUsSuccess(response.data.data.about));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAboutUsFailure(errorPreview));
    }
  }
}

function* privacyPolicies() {
  try {
    const response = yield $host.get(`/terms_conditions/policy`);

    yield put(getPrivacyPoliciesSuccess(response.data.data.privacy_policy));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getPrivacyPoliciesFailure(errorPreview));
    }
  }
}

function* termsConditions() {
  try {
    const response = yield $host.get(`/terms_conditions/terms`);
    yield put(getTermsConditionsSuccess(response.data.data.terms_conditions));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getTermsConditionsFailure(errorPreview));
    }
  }
}

export default function* commonSaga() {
  yield takeLatest(getAboutUs.type, aboutUs);
  yield takeLatest(getPrivacyPolicies.type, privacyPolicies);
  yield takeLatest(getTermsConditions.type, termsConditions);
}
