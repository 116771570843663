import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderCancelReason: [],
  isLoading: false,
  success: null,
  error: null,
};

export const orderBookingSlice = createSlice({
  name: 'orderBooking',
  initialState,
  reducers: {
    // book schedule
    bookSchedule: (state, action) => {
      state.isLoading = true;
    },
    bookScheduleSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    bookScheduleFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // book service
    bookService: (state, action) => {
      state.isLoading = true;
    },
    bookServiceSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    bookServiceFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // create payments
    createPayment: (state, action) => {
      state.isLoading = true;
    },
    createPaymentSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    createPaymentFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // cancel order
    orderCancel: (state, action) => {
      state.isLoading = true;
    },
    orderCancelSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    orderCancelFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // order cancel reasons get
    getOrderCancelReasons: (state, action) => {
      state.isLoading = true;
    },
    getOrderCancelReasonsSuccess: (state, action) => {
      state.isLoading = false;
      state.orderCancelReason = action.payload;
    },
    getOrderCancelReasonsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // order complete
    completeOrder: (state, action) => {
      state.isLoading = true;
    },
    completeOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    completeOrderFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // reset order states
    resetOrderBooking: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  bookSchedule,
  bookScheduleSuccess,
  bookScheduleFailure,
  bookService,
  bookServiceSuccess,
  bookServiceFailure,
  createPayment,
  createPaymentSuccess,
  createPaymentFailure,
  orderCancel,
  orderCancelSuccess,
  orderCancelFailure,
  getOrderCancelReasons,
  getOrderCancelReasonsSuccess,
  getOrderCancelReasonsFailure,
  completeOrder,
  completeOrderSuccess,
  completeOrderFailure,
  resetOrderBooking,
} = orderBookingSlice.actions;

export default orderBookingSlice.reducer;
