import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  bookSchedule,
  bookScheduleSuccess,
  bookScheduleFailure,
  bookService,
  bookServiceSuccess,
  bookServiceFailure,
  createPayment,
  createPaymentSuccess,
  createPaymentFailure,
  orderCancel,
  orderCancelSuccess,
  orderCancelFailure,
  getOrderCancelReasons,
  getOrderCancelReasonsSuccess,
  getOrderCancelReasonsFailure,
  completeOrder,
  completeOrderSuccess,
  completeOrderFailure,
  resetOrderBooking,
} from './orderBookingSlice';

function* bookScheduled(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post('/service-booking-schedule/booked-schedule', payload);
    // yield put(bookScheduleSuccess(response.data.data));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(bookScheduleFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(bookScheduleFailure(errorPreview));
    }
  }
}

function* bookServices(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post('/booking/book-services', payload);
    // yield put(bookServiceSuccess(response.data.data));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(bookServiceFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(bookServiceFailure(errorPreview));
    }
  }
}

function* createPayments(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post('/booking/create-payment', payload);
    // yield put(orderCancelSuccess(response.data.data));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(createPaymentFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(createPaymentFailure(errorPreview));
    }
  }
}

function* cancelOrder(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post('/booking/cancel-order-by-user/:id', payload);
    // yield put(createPaymentSuccess(response.data.data));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(orderCancelFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(orderCancelFailure(errorPreview));
    }
  }
}

function* orderCancelReasons() {
  try {
    const response = yield $authHost.get('/order-cancellation-reason');
    yield put(getOrderCancelReasonsSuccess(response.data.data.order_cancellation_reason));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(getOrderCancelReasonsFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getOrderCancelReasonsFailure(errorPreview));
    }
  }
}

function* orderComplete(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.put('/booking/complete-order-by-user/:id', payload);
    // yield put(completeOrderSuccess(response.data.data));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(completeOrderFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(completeOrderFailure(errorPreview));
    }
  }
}

export default function* orderBookingSaga() {
  yield takeLatest(bookSchedule.type, bookScheduled);
  yield takeLatest(bookService.type, bookServices);
  yield takeLatest(createPayment.type, createPayments);
  yield takeLatest(orderCancel.type, cancelOrder);
  yield takeLatest(getOrderCancelReasons.type, orderCancelReasons);
  yield takeLatest(completeOrder.type, orderComplete);
}
