import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingsList: [],
  businessBookings: [],
  isLoading: false,
  success: null,
  error: null,
  cancelBookingStatus: null,
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    getBookingList: (state) => {
      state.isLoading = true;
    },
    getBookingListSuccess: (state, action) => {
      state.isLoading = false;
      state.bookingsList = action.payload;
    },
    getBookingListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // current business bookings
    getBusinessBookings: (state, action) => {
      state.isLoading = true;
    },
    getBusinessBookingsSuccess: (state, action) => {
      state.isLoading = false;
      state.businessBookings = action.payload;
    },
    getBusinessBookingsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetBookingsState: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
    cancelBookingRequest: (state) => {
      state.isLoading = true;
      state.cancelBookingStatus = null;
    },
    cancelBookingSuccess: (state, action) => {
      state.isLoading = false;
      state.cancelBookingStatus = 'success';
      // Optionally update the bookingsList or handle success
    },
    cancelBookingFailure: (state, action) => {
      state.isLoading = false;
      state.cancelBookingStatus = 'failure';
      state.error = action.payload;
    },
  },
});

export const {
  getBookingList,
  getBookingListSuccess,
  getBookingListFailure,
  getBusinessBookings,
  getBusinessBookingsSuccess,
  getBusinessBookingsFailure,
  cancelBookingRequest,
  cancelBookingSuccess,
  cancelBookingFailure,
  resetBookingsState,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;
