import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getAllBusinesses,
  getAllBusinessesSuccess,
  getBusinessServices,
  getBusinessServicesSuccess,
  getBusinessSpecialists,
  getBusinessSpecialistsSuccess,
  getBusinessService,
  getBusinessServiceSuccess,
  getBusinessDetails,
  getBusinessDetailsSuccess,
  getAllBusinessesServiceTypes,
  getAllBusinessesServiceTypesSuccess,
} from './businessesSlice';

function* allBusinesses() {
  try {
    const response = yield $host.get(`/businesses/all-businesses`);
    yield put(getAllBusinessesSuccess(response.data.data.businesses));
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield console.error(errorPreview);
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* getBusinessServicesList(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/business-services/business-all-services/${payload}`);
    if (response.data.code === 200) {
      yield put(getBusinessServicesSuccess(response.data.data.business_services));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield console.error(errorPreview);
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* specialists(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/business-specialists/all-specialist/${payload}`);
    yield put(getBusinessSpecialistsSuccess(response.data.data.specialists));
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* getSpecificBusinessService(action) {
  const { businessId, serviceId } = action.payload;
  try {
    const response = yield $authHost.get(
      `/business-services/business-service/${businessId}/${serviceId}`,
    );
    if (response.data.code === 200) {
      yield put(getBusinessServiceSuccess(response.data.data.business_service));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield console.error(errorPreview);
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* getBusinessDetailsSaga(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/businesses/business-details/${payload}`);

    if (response.data.code === 200) {
      yield put(getBusinessDetailsSuccess(response.data.data));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield console.error(errorPreview);
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* getAllBusinessServiceTypes(action) {
  const { payload } = action;
  try {
    const response = yield $host.get(`/businesses/service-type/${payload}`);

    if (response.data.code === 200) {
      yield put(getAllBusinessesServiceTypesSuccess(response.data.data.business_service_types));
    }
  } catch (error) {
    if (error.response.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield console.error(errorPreview);
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield console.error(errorPreview);
    }
  }
}

function* businessSaga() {
  yield takeLatest(getAllBusinesses.type, allBusinesses);
  yield takeLatest(getBusinessServices.type, getBusinessServicesList);
  yield takeLatest(getBusinessSpecialists.type, specialists);
  yield takeLatest(getBusinessService.type, getSpecificBusinessService);
  yield takeLatest(getBusinessDetails.type, getBusinessDetailsSaga);
  yield takeLatest(getAllBusinessesServiceTypes.type, getAllBusinessServiceTypes);
}

export default businessSaga;
