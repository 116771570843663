import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  success: null,
  error: null,
  reviews: [],
  totalRating: 0,
};

const serviceReviewSlice = createSlice({
  name: 'serviceReview',
  initialState,
  reducers: {
    fetchReviewsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchReviewsSuccess: (state, action) => {
      state.isLoading = false;
      state.reviews = action.payload;
    },
    fetchReviewsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    submitReviewRequest: (state) => {
      state.isLoading = true;
      state.success = null;
      state.error = null;
    },
    submitReviewSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
    },
    submitReviewFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetServiceReviewState: (state) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  fetchReviewsRequest,
  fetchReviewsSuccess,
  fetchReviewsFailure,
  submitReviewRequest,
  submitReviewSuccess,
  submitReviewFailure,
  resetServiceReviewState,
} = serviceReviewSlice.actions;

export default serviceReviewSlice.reducer;
