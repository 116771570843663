import { takeLatest, put } from 'redux-saga/effects';
import { $authHost, $host } from '../axios';
import {
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,
  getAllBookingSchedulesSuccess,
  getAllBookingSchedulesError,
} from './bookingRescheduleSlice';

function* bookingScheduleUpdate(action) {
  const { payload } = action;
  const formData = new FormData();
  formData.append('id', payload.schedule_id);
  formData.append('service_start_time', payload.service_booking_time.time.start_time);
  formData.append('date', payload.service_booking_date);
  formData.append('booking_id', payload.booking_id);
  formData.append('service_id', payload.service_id);

  try {
    const response = yield $authHost.post(`/service-booking-schedule/reschedule-service`, formData);
    yield put(updateBookingScheduleSuccess(response?.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBookingScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBookingScheduleError(errorPreview));
    }
  }
}

function* fetchAllBookingSchedules() {
  try {
    const response = yield $host.get(`/service-booking-schedule/get-all`);
    yield put(getAllBookingSchedulesSuccess(response.data.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getAllBookingSchedulesError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAllBookingSchedulesError(errorPreview));
    }
  }
}

export default function* bookingScheduleSaga() {
  yield takeLatest(updateBookingSchedule.type, bookingScheduleUpdate);
  yield takeLatest(getAllBookingSchedules.type, fetchAllBookingSchedules);
}
